import { Injectable } from '@angular/core';
import {StorageService} from '../storage/storage.service';
import { environment } from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MapModel} from '../../models/MapModel';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {Poi} from '../../models/Poi';
import {fromPromise} from 'rxjs/internal-compatibility';
import {AuthCode} from '../../models/AuthCode';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private storageService: StorageService, private httpClient: HttpClient) { }

  getMaps(): Observable<MapModel[]> {
    return fromPromise(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.get<MapModel[]>(environment.baseUrl + 'api/maps', {headers}))
    );
  }

  getPoi(): Observable<Poi[]> {
    return fromPromise(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.get<Poi[]>(environment.baseUrl + 'api/poi', {headers}))
    );
    //   .pipe(
    //   catchError(this.handleError<Poi[]>('Error occured'))
    // );
  }

  auth(deviceId, authCode): Observable<AuthCode> {
    const body = {
      authCode,
      deviceId
    };
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.httpClient.post<AuthCode>(environment.baseUrl + 'api/auth', body,{headers:{'Content-Type': 'application/json'}} );
  }

  generateCode(expireDays): Observable<AuthCode> {
    const body = {
      expireDays
    };
    return fromPromise(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.post<AuthCode>(environment.baseUrl + 'api/generate-auth-code', body, {headers}))
    );
  }

  private getHeaders(): Promise<HttpHeaders> {
    return Promise.all([
      this.storageService.getAuthCode(),
      this.storageService.getDeviceId()
    ]).then(values => new HttpHeaders({
        'auth-code': values[0],
        'device-id': values[1],
      // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }));
  }
}
