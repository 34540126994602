import { NgModule } from '@angular/core';
import {LanguageComponent} from './language/language.component';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {GenerateCodeComponent} from './generate-code/generate-code.component';
import {SponsorComponent} from './sponsor/sponsor.component';

@NgModule({
  declarations: [
    LanguageComponent,
    ToolbarComponent,
    GenerateCodeComponent,
    SponsorComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  exports: [
    LanguageComponent,
    ToolbarComponent,
    GenerateCodeComponent,
    SponsorComponent,
    IonicModule,
    CommonModule,
    FormsModule,
    TranslateModule ]
})
export class SharedModule { }
